.sidebar {
  text-align: left;
  height: 100%;
  width: 250px;
  position: fixed;
  top: 70px;
  left: -250px;
  background-color: #ffffff;
  overflow-x: hidden;
  transition: 0.3s;
  padding-top: 40px;
  border-right: 1px solid #edf2f7;
  z-index: 1;
}

.sidebar.open {
  left: 0;
}

.sidebar ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.sidebar a {
  padding: 8px 8px 8px 20px;
  text-decoration: none;
  color: #202020;
  font-weight: 500;
  display: block;
  transition: 0.3s;
}

.sidebar a:hover {
  color: #818181;
}

.sidebar li {
  position: relative;
  transition: background-color 0.3s ease;
}

.sidebar li.selected {
  background-color: #f4ebfb;
}

.sidebar li.selected::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 5px;
  max-height: 40px;
  background-color: #7000c8;
  transition:
    top 0.3s ease,
    height 0.3s ease;
}
