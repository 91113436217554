.input-container {
  color: #004159;
  margin-bottom: 15px;
  width: 100%;
}

.input-label {
  margin-bottom: 5px;
  font-weight: 600;
}

.input-box {
  border: 1px solid #ebf2fa;
  border-radius: 6px;
  height: 40px;
  padding: 15px;
  width: 100%;
}

.input-box::placeholder {
  color: #b5b5bd;
}

.input-box:focus {
  border: 1px solid #d4d4d4;
  outline-offset: 0px;
  outline: none;
  box-shadow: 0 0 0 1px #f4ebfb;
}
