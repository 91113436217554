.custom-table {
  width: 100%;
  border-collapse: collapse;
}

.custom-table-head {
  background-color: #fafafb;
}

.custom-table-head-row {
  color: #8492a6;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0.06em;
  border: 1px solid #edf2f7;
}

.custom-table-head-cell {
  padding: 10px;
}

.custom-table-head-cell-allow-sort {
  padding: 10px;
  cursor: pointer;
}

.custom-table-body {
  background-color: #ffffff;
}

.custom-table-body-row {
  color: #425466;
  font-size: 14px;
  border: 1px solid #edf2f7;
}

.custom-table-body-cell {
  padding: 10px;
  /* text-align: left; */
}

.pagination {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: 10px;
  font-size: 14px;
  color: #425466;
}

.pagination-info {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
}

.pagination-btn {
  background-color: inherit;
  border: none;
  color: #425466;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 7px;
  padding-right: 7px;
}
.pagination-btn:hover {
  background-color: #edf2f7;
  border-color: #edf2f7;
}
.pagination-btn:active,
.pagination-btn:visited,
.pagination-btn:focus {
  background-color: #edf2f7;
  outline: none;
  box-shadow: none;
}

.elements-info {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: auto;
}
