.layout-container {
  display: grid;
  grid-template-rows: 70px 1fr; /* 70px for header. The empty space for content */
  grid-template-columns: 250px 1fr; /* 0px empty space for content */
  grid-template-areas:
    'header header'
    'sidebar main'
    'sidebar footer';
  height: 100vh;
  overflow: hidden;
}

.headerbar {
  grid-area: header;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #edf2f7;
}

.sidebar {
  grid-area: sidebar;
  overflow-y: auto;
}

.main-content {
  grid-area: main;
  overflow-y: auto;
  padding: 40px;
  background-color: #f7f9fb;
}

.footer {
  grid-area: footer;
  font-size: 14px;
  height: 40px;
  background-color: #f7f9fb;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid #edf2f7;
}

@media (max-width: 768px) {
  .footer {
    margin-left: 0px;
  }
}
