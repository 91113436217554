.custom-dropdown-toggle {
  background-color: white !important;
  border-color: white !important;
  color: #202020 !important;
}
.custom-dropdown-toggle:hover,
.custom-dropdown-toggle:focus,
.custom-dropdown-toggle:active,
.custom-dropdown-toggle.show {
  background-color: white !important;
  color: #202020 !important;
  border-color: white !important;
  box-shadow: none !important;
}
.custom-dropdown-toggle::after {
  margin-top: 10px;
  margin-left: 10px;
}

.custom-dropdown-menu {
  border-color: #edf2f7;
}

.custom-dropdown-item {
  background-color: white !important;
  border-color: white !important;
  color: #202020 !important;
}
.custom-dropdown-item:hover {
  background-color: #f4ebfb !important;
}
.custom-dropdown-item:focus,
.custom-dropdown-item:active,
.custom-dropdown-item.show {
  background-color: #f4ebfb !important;
  color: #202020 !important;
  border-color: white !important;
  box-shadow: none !important;
}
