.filter-container {
  display: flex;
  margin-bottom: 15px;
}

.filter {
  margin-right: 10px;
  position: relative;
  top: 100%;
  left: 0;
}

.filter-selector {
  display: flex;
  align-items: center;
  width: fit-content;
  border: 1px dashed rgba(32, 32, 32, 0.2);
  border-radius: 20px;
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 7px;
  padding-right: 7px;
  cursor: pointer;
  opacity: 85%;
}

.filter-selector-close {
  cursor: pointer;
  margin-right: 10px;
  font-size: 10px;
}

.filter ul {
  list-style-type: none;
  width: max-content;
  border: 1px solid rgba(32, 32, 32, 0.2);
  border-radius: 8px;
  padding: 0px;
  margin: 0px;
  background-color: #ffffff;
  position: absolute;
  z-index: 100;
  box-shadow: 0px 4px 8px rgba(32, 32, 32, 0.1);
  margin-top: 5px;
}

.filter li {
  opacity: 85%;
  padding: 8px;
}

.filter li:hover {
  background-color: #f4ebfb;
}
