body {
  margin: 0;
  padding: 0;
  height: 100%;
  background-color: #ffffff;
  font-family: 'Poppins';
  font-size: 16px;
  font-weight: 400;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.container {
  padding: 0px;
}

.btn {
  font-family: 'Poppins';
  font-size: 16px;
  font-weight: 400;
}

.btn-primary {
  background-color: #7000c8;
  border-color: #7000c8;
}
.btn-primary:hover {
  background-color: #728efd;
  border-color: #728efd;
}
.btn-primary:active,
.btn-primary:visited,
.btn-primary:focus {
  background-color: #7000c8;
  border-color: #7000c8;
  box-shadow: none;
}
.btn-primary:disabled {
  background-color: #c5c5c5;
  border-color: #c5c5c5;
}
