.title {
  font-weight: 600;
  font-size: 36px;
  color: #202020;
  margin-bottom: 10px;
}

.sub-title {
  margin-bottom: 40px;
}
